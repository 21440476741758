
import { BodyArea } from './BodyArea'
import { BodyDivision } from './BodyDivision'
import { BodySearch } from './BodySearch'
import React, { useState } from 'react'
import mock from './mock.json';
const data=mock.items;
export const MainBody = (props:any) => {

    function update(props:any)
    {
        console.log("update started");
        console.log(props);
    }

    const [cartItems,setcartItems] = useState<any[]>([]);
    return (
        
        <div className="MainBody">
        <>
        <BodyDivision onClick={props.onClick}/>
        <BodySearch/>
        </>
            
        </div>
    )
}
