import React from 'react'

export const Mainlogo3 = (props:any) => {
    return (
       
            <div id="logo-img">
            <div className="logo-text">{props.title}</div>
            {/* <div className="logo-course">{props.name}</div> */}
            </div>

      
    )
}
