import React from 'react'

export const ProfileForms = () => {
    return (
        <div id="expertise" >  
                <div id="ext-on">
                <input type="radio" id="radio-style3" name="expt"/>
                 <label>&nbsp;Java</label>
                </div>
                <div id="ext-on">
                <input type="radio" id="radio-style3" name="expt"/>
                 <label>&nbsp;React</label>
                </div>

                <div id="ext-on">
                <input type="radio" id="radio-style3" name="expt"/>
             <label>&nbsp;Backend</label>
                 </div>
             </div>
    )
}
