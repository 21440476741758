import React from 'react'
import {BodyDivision3  } from './BodyDivision3';
import { BodyArea3 } from './BodyArea3';

import { BodySearch3 } from './BodySearch3';
export const MainBody3 = () => {
    return (
        <div className="MainBody">
        <>
        <BodyDivision3/>
        <BodySearch3/>
        </>
            
        </div>
    )
}
