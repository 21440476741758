import React from 'react'

export const SortBar = (props:any) => {
    return (
        <div>
             <div className="sortArea">
               <select name="sort-price" id="sorting">
                    <option selected disabled hidden>{props.id}</option>
                    <option value="ascending">Low-to-High</option>
                    <option value="descending">High-to-low</option>
                </select>
                </div>
        </div>
    )
}
