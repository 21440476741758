import React from 'react'

export const ProfileInterest = () => {
    return (
        <div id="interest-area" className="input">Your Area of Interest
        
        <div id="interest" className="it-opn"> 
        
         <input type="checkbox" id="checkbox-style"/>
         <label>
             &nbsp;
             Designer</label>
         </div>
         <div id="interest" >
         <input type="checkbox" id="checkbox-style"/>
         <label>
             &nbsp;
             Developer</label></div>
         <div id="interest">
         <input type="checkbox" id="checkbox-style"/>
         <label>
             &nbsp;
             Project Manager</label></div>
         <div id="interest">
         <input type="checkbox" id="checkbox-style"/>
         <label>
             &nbsp;
             Sales</label>
             </div>
 </div>
    )
}
