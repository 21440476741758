import React from 'react'
import {Profile} from './Profile'
import { Cart } from './Cart';
import { Wishlist } from './Wishlist';
export const TopBar = () => {
    return (
        <div id ="main-bar">

            <a href="./">< div id="logo"></div></a>
            <a href="./"><div id="courses"><b>COURSES</b></div> </a>
            <Wishlist title="MY WISHLIST"/>
            <Cart/>
            <a href="/profile"><div id="profile" >.......
                
            </div>
            </a>
            
        </div>
    )
}
