import React from 'react'
import { MainBody } from './MainBody';
import { Mainlogo } from './Mainlogo';
import {TopBar} from './TopBar';


export const Headerfile = () => {
    return (
        <>
    <header className="header">
      <TopBar/>
      </header>
      <Mainlogo title="Discover Latest Courses on" name="React"/>
      <MainBody/>

            
        </>
    )
}
