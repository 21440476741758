import React from 'react'
import { Headerfile } from './Headerfile'
import {TopBar} from './TopBar'
import {Mainlogo2} from './Mainlogo2'
import { ProfileStructure } from './ProfileStructure'


export const ProfilePage = () => {
    return (
        <div > 
       <header className="header">
      <TopBar/>
      </header>
      <Mainlogo2 title="Profile Page"/>
      
        
        <ProfileStructure/>
        </div>
    )
}
