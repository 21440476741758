import React from 'react'

export const CartDetails = () => {
    return (
        <>
        <div id="CartValue">
            <div id="Cart-title"></div>
            <div id="cart-image"></div>
            <div id="values-name">
                Responsive design course XYZ how to design responsive templates

            </div>
            <div id="cartTotal">Rs 563/-</div>
        </div>
            
        </>
    )
}
