import React from 'react';
import logo from './logo.svg';
import './App.css';
import { TopBar } from './Components/TopBar';
import { Headerfile } from './Components/Headerfile';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import {ProfilePage} from './Components/ProfilePage'
import {CheckoutPage} from './Components/CheckoutPage'
import { start } from 'repl';
import { MyWishlist } from './Components/MyWishlist';
import { CourseDetails } from './Components/CourseDetails';

function App() {
  return (
    <Router>
    <div className="web-app" >
      <Route exact path="/" render={
        ()=>{
          return(
            <>
             <Headerfile/>

            </>
          );
        }
      }/>
      <Route path="/profile" render={
        ()=>{
          return(
            <>
             <ProfilePage/>

            </>
          );
        }

      } />
      <Route path="/checkout" render={
        ()=>{
          return(
            <>
             <CheckoutPage/>

            </>
          );
        }

      } />
      <Route path="/wishlist" render={
        ()=>{
          return(
            <>
             <MyWishlist/>

            </>
          );
        }

      } />
      <Route path="/details" render={
        ()=>{
          return(
            <>
             <CourseDetails/>

            </>
          );
        }

      } />
     
    </div>
    </Router>
  );
}

export default App;
