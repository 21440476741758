import React from 'react'
import mock from './mock.json';
import { useState } from 'react';
import { AddToCart } from './AddToCart';
import { setCommentRange } from 'typescript';

// export const BodyArea = () => {
//     return (
//         <>
//         <div className="Barea">
//             <div id="Bimage"></div>
//             <div id="Btitle">
//             <div id="Bname">
//                 Responsive design Course XYZ How to design responsive templates
//             </div>
//             <div id="Btag">React</div>
//             <div id="Btag">React</div>
//             </div>
//             <div id="Bauthor">Joseph Marie</div>
//             <div id="Bwishlist"></div>
//             <div id="Bcost-price">Rs 563</div>
//             <div id="Bdiscount">Rs 912</div>
//             <div id="Badd-cart"><button id="add-items">Add to Cart</button></div>
//             <div id="next-page"></div>

//         </div>
//         </>
//     )
// }
export const BodyArea = (props:any) => 
{


    return (
        <div>
            <div className="card-class">
                <Print data={props.data} onClick={props.onClick}/>
            </div>
            
        </div>
    )
}
function PrintItems(props:any)
{

    let wish:string="Bwishlist";
    if(props.star=="0")
    {wish="Bwishlist1";}
    return(
    <>
           <div className="Barea">
            <div id="Bimage"></div>
            <div id="Btitle">
          <div id="Bname">
              {props.Cname}
          </div>
           <div id="Btag">{props.topic1}</div>
            <div id="Btag">{props.topic2}</div>
           </div>
            <div id="Bauthor">{props.authName}</div>
            <div id={wish}></div>
             <div id="Bcost-price">{props.costPrice}</div>
              <div id="Bdiscount">{props.discount}</div>
            <div id="Badd-cart"><button id="add-items" onClick={{...props}}>Add to Cart</button></div>
              <div id="next-page"></div>
    
           </div>
            </>
)
}
function Print(props:any){
    const data=props.data;
    return(
        <div className="container">
            {data.map((data:any)=>(
                <>
                <PrintItems{...data} onClick={props.onClick}/>
                </>
            ))}
        </div>
   
   );
}





