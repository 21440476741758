import React from 'react'
import {BodyDivision2} from './BodyDivision2'
import {BodySearch2} from  './BodySearch2'
// Main body of checkpout pge
export const MainBody2 = () => {
    

    return (
        <div className="MainBody2">
        <>
        <BodyDivision2/>
        <BodySearch2/>
        </>
            
        </div>
    )
}
