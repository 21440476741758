
import React, { useEffect, useState } from "react";

import {CheckoutButton} from './CheckoutButton'

export const BodySearch2 = () => {
   
    return (
        <div id="csideSearch">
            {/* <div id="search-area">
            <input id="search" placeholder="search here"/>
            <div id="search-icon"></div>
            
            </div> */}
            <div id="check-Cart">
                <div id="check-Cheading">Total Amount</div>
                <div id="check-Cdetails">
                    Rs. 1689
                    {/* <CartDetails/>
                    <CartDetails/>
                    <CartDetails/>
                    <CartDetails/>
                    <CartDetails/> */}

                </div>
                <div id="check-TotalAmount">
                <CheckoutButton/>
                    {/* <div id="check-total">RS 1689/-</div> */}
                </div>
            </div>
            
        </div>
    )
}
