import React from 'react'
import {TopBar} from './TopBar'
import {Mainlogo3} from './Mainlogo3'
import { MainBody3 } from './MainBody3'
export const MyWishlist = () => {
    return (
        
        <div > 
       <header className="header">
      <TopBar/>
      </header>
      <Mainlogo3 title="Shopping Cart"/>
     <MainBody3/>
        </div>
    )
}
