import React from 'react'

export const ProfileAbout = () => {
    return (
        <div>
             <div id="about" className="input">About Yourself
                            <input id="abt"  type="textbox"/>
                    </div>
        </div>
    )
}
