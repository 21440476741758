import React from 'react'
import { mockComponent } from 'react-dom/test-utils'
import {BodyArea3} from './BodyArea3'
import {SortBar} from './SortBar'
import mock from './mock.json';
export const BodyDivision3 = () => {
    return (
        <>
        <div id="BodyMenu">
            <div id="bmenubar">
                <div id="btext">
                    ALL COURSES

                </div>   
                <SortBar id="Course Price"/>
        </div>
        
        <div>
            <BodyArea3 data={mock.items}/>


        </div>
        </div>
            
        </>
    )
}
