import React from 'react'
import {TopBar} from './TopBar'
import {Mainlogo} from './Mainlogo'


export const CourseDetails = () => {
    return (
        <div > 
        <header className="header">
       <TopBar/>
       </header>
       <Mainlogo title="Discover Latest Courses on" name="React"/>
       
         
             
         </div>
    )
}
