import React from 'react'
import mock from './mock.json';
import {BodyArea2} from './BodyArea2'
import { CourseRecommend } from './CourseRecommend';
export const BodyDivision2 = () => {
    return (
        <div>
            <>
        <div id="BodyMenu">
            <div id="bmenubar">
                <div id="btext">
                    COURSES IN CART

                </div>
                
                 
           
        </div>
        
        <div>
            <BodyArea2 data={mock.items.slice(0,3)}/>
            
        </div>
        <div id="cd">
            <div id="cd_menu">
                <div id="cd-text">
                    Recommended Courses
                </div>
            </div>
            <div>
             <CourseRecommend data={mock.items}/>
            </div>
        </div>
        </div>
            
        </>
        </div>
    )
}
