import React from 'react'
import { Headerfile } from './Headerfile'
import { MainBody2 } from './MainBody2'
import {Mainlogo3} from './Mainlogo3'
import {TopBar} from './TopBar'

export const CheckoutPage = () => {
    return (
        <div > 
       <header className="header">
      <TopBar/>
      </header>
      <Mainlogo3 title="Shopping Cart"/>
      <MainBody2/>
        
            
        </div>
    )
}
