import React from 'react'
import mock from './mock.json';
export const BodyArea2 = (props:any) => {
    return (
        
            <div>
            <div className="card-class">
                <Print data={props.data}/>
            </div>
            
        </div>
        

    )
}
function PrintItems(props:any)
{
if(props.cart=="0")
{return(<CartEmpty/>);}


{return(
    <>
           <div className="Barea">
            <div id="Bimage"></div>
            <div id="Btitle">
          <div id="Bname">
              {props.Cname}
          </div>
           <div id="Btag">{props.topic1}</div>
            <div id="Btag">{props.topic2}</div>
           </div>
            <div id="Bauthor">{props.authName}</div>
            <div id="Bwishlist"></div>
             <div id="Bcost-price">{props.costPrice}</div>
              <div id="Bdiscount">{props.discount}</div>
              
              <div id="next-page"></div>
    
           </div>
            </>
)
}
}
function Print(props:any){
    const data=props.data;
    return(
        <div className="container">
            {data.map((data:any)=>(
                <>
                <PrintItems{...data}/>
                </>
            ))}
        </div>
   
   );
}
function CartEmpty(){
    return(
        <>
        </>
    );
}

