import React from 'react'
import { CartDetails } from './CartDetails'

export const BodySearch = () => {
    return (
        <div id="sideSearch">
            <div id="search-area">
            <input id="search" placeholder="search here"/>
            <div id="search-icon"></div>
            
            </div>
            <div id="Cart">
                <div id="Cheading">YOUR CART DETAILS</div>
                <div id="Cdetails">
                    your cart is empty right now.Please add courses in the cart from the list.
                   
                    <CartDetails/>
                    <CartDetails/>
                    <CartDetails/>
                    <CartDetails/>

                </div>
                <div id="TotalAmount">
                    <div id="Ctext">Total Cart Value</div>
                    <div id="total">RS 3679/-
                    <a href="\checkout"><div id="checkout-btn">
                        Go to checkout
                    </div></a>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
