import React from 'react'

export const Wishlist = (props:any) => {
    return (
        <>
       <a href="/wishlist"><div id ="wishlist">
            {props.title}</div></a>
        </>
    )
}
