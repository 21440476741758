import React from 'react'
import {SortBar} from './SortBar';
import {BodyArea} from './BodyArea';
import mock from './mock.json';

export const BodyDivision = (props:any) => {
    return (
        <>
        <div id="BodyMenu">
            <div id="bmenubar">
                <div id="btext">
                    ALL COURSES

                </div>
                
                <SortBar id="Course Price"/>
                 
           
        </div>
        
        <div>
            <BodyArea data={mock.items} onClick={props.onClick}/>
            {/* <BodyArea data={mock.items}/>
            <BodyArea data={mock.items}/>
            <BodyArea data={mock.items}/>
            <BodyArea data={mock.items}/> */}
        </div>
        </div>
            
        </>
    )
}
