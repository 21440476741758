import React from 'react'

export const CheckoutButton = () => {
    return (
        <div>
            <div id="Ctext">
               
            
                    <div id="checkOut_1"><button id="checkOut">checkout</button></div>
                    
                 
             </div>
        </div>
    )
}

