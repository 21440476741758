import React from 'react'
import { ProfileAbout } from './ProfileAbout';
import { ProfileForms } from './ProfileForms';
import { ProfileInterest } from './ProfileInterest';
export const ProfileStructure = () => {
    return (
        <>
         <div className="profile">
            
            <div id="P_img">

            </div>
            <div id="Main">
                <div id="p_names">
                
                <div id="name" className="input">
                    Display Name
                <input id="Enter_name" placeholder="Enter Name " type="text"/>
                </div>
                
                <div id="first-name" className="input">
                    First Name
                <input id="Enter_name" placeholder="Enter Name " type="text"/>
                 </div>
                 
                 <div id="last-name" className="input">
                     Last Name
                <input id="Enter_name" placeholder="Enter Name " type="text"/>
                 </div>
                    </div>
                    
                    <ProfileAbout/>
                   
                    <ProfileInterest/>
                    <div id="stud" className="input">Are you student or Professional
                            <div id="opn">
                            <input type="radio" id="radio-style1" name="sp"/>
                                <label>&nbsp;
                                    Student
                                    </label>
                            </div>    
                            <div id="opn">
                            <input type="radio" id="radio-style1" name="sp"/>
                                <label>&nbsp;
                                    Professional
                                    </label>
                            </div>
                    </div>
                    
                    <div id="info">
                        <div  className="input">How much of experience you have? </div>
                        <div id="exp">
                                <div id="option">
                                <input type="radio" id="radio-style2" name="exp"/>
                                        <label>&nbsp;0-5</label>
                                </div>   
                                <div id="option">
                                <input type="radio" id="radio-style2" name="exp"/>
                                        <label>&nbsp;5-10</label>
                                        </div>   
                                <div id="option">
                                <input type="radio" id="radio-style2" name="exp"/>
                                        <label>&nbsp;10 n Above</label>
                                </div>       
                        </div>
                        <div className="input">What is your expertise</div>
                            
                            <ProfileForms/>
                        <div id="role" className="input">Mention your role
                            <input id="rfeild" placeholder="Type Your Role" type="text"/>
                        </div>
                    </div >
                    <div id="footer">
                           SAVE
                    </div>
                      
                    </div>

            </div>
        </>
    )
}
